import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

import {
  Layout, NewsCard, SectionTitle,
} from 'UI';
import { SourceTitle } from 'UI/NewsCard/NewsCard.styled';

export const Container = styled(Layout)`
  grid-column: 1/-1;
  align-items: start;

  grid-template-areas:
    'a a b b b b b b b b'
    'a a c c c c c c c c';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 0;
    grid-template-areas:
      'a a a a a a'
      'b b b b b b'
      'c c c c c c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
    row-gap: 20px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c';
  }
`;

export const Title = styled(SectionTitle)`
  grid-area: a;
  grid-row: 1;
  margin: 0;
  position: sticky;
  top: 1rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    position: static;
  }
`;

export const Wrapper = styled(Layout)`
  grid-area: b;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  row-gap: 5.7em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const Card = styled(NewsCard)`
  grid-column: span 4;
  grid-row: span 1;

  ${SourceTitle} {
    display: none;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const ShowMore = styled.button`
  grid-row: span 1;
  grid-area: c;
  cursor: pointer;
  justify-self: start;
  padding: 0;
`;
