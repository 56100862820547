import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IMediaCoverage } from 'interfaces/press';

import Videos from '../Videos/Videos';

const query = graphql`
  query {
    interviews: allStrapiMediaCoverage(
      sort: {fields: date, order: DESC}
      filter: {type: {eq: "interview"}}
      limit: 20
    ) {
      nodes {
        link
        title
        id: strapiId
        media {
          ...MediaImageFragment
        }
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
`;

interface IMediaCoverageQuery {
  interviews: {
    nodes: IMediaCoverage[];
  };
}

const MediaCoverageQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IMediaCoverageQuery) => {
      if (!data?.interviews?.nodes?.length) return null;
      const { interviews } = data;

      return (
        <Videos
          header="Interviews"
          nodes={interviews.nodes}
        />
      );
    }}
  />
);

export default MediaCoverageQuery;
