import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IQueryPage } from 'interfaces/page';

import Head from 'components/Layout/Head/Head';

import Title from 'components/blocks/MediaCoverage/Title/Title';
import Interviews from 'components/blocks/MediaCoverage/Interviews/Interviews';
import Articles from 'components/blocks/MediaCoverage/Articles/Articles';

const query = graphql`
  query {
    page: strapiMediaCoveragePage {
      meta {
        ...StrapiMetaFragment
      }
    }
  }
`;

const MediaCoveragePage: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryPage) => {
      const siteInfo = data.page.meta;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
            slug="media-coverage"
          />
          <Title text={siteInfo.title} />
          <Articles />
          <Interviews />
        </>
      );
    }}
  />
);

export default MediaCoveragePage;
