import React, { FC, useEffect, useState } from 'react';

import { IMetaPreview } from 'interfaces/page';
import { IMediaCoverage } from 'interfaces/press';

import { MoreText } from 'UI';

import {
  Container, Title, ShowMore, Header, Dropdown,
} from './NewsList.styled';
import NewsLinks from './NewsLinks/NewsLinks';

interface INewsList {
  nodes: IMediaCoverage[];
  header?: string;
  defaultImage?: IMetaPreview;
}

const MAX_ITEMS = 4;

const NewsList: FC<INewsList> = ({
  nodes, header, defaultImage,
}) => {
  const [maxShown, setMaxShown] = useState<number>(MAX_ITEMS);
  const [articles, setArticles] = useState<IMediaCoverage[]>([]);
  const [currentYear, setCurrentYear] = useState<number>();
  const [allYears, setAllYears] = useState<number[]>();

  const handleOpen = () => setMaxShown(nodes.length);

  const handleClose = () => setMaxShown(MAX_ITEMS);

  const onChangeYear = (value: number) => {
    setCurrentYear(value);
  };

  const getYearsValues = () => {
    const yearsValues = allYears?.map((value) => ({
      label: String(value),
      value,
    }));

    if (!allYears?.length) return null;

    return [
      {
        label: 'All Years',
        value: -1,
      },
      ...yearsValues,
    ];
  };

  useEffect(() => {
    if (currentYear === -1) {
      setArticles(nodes);
    } else {
      const filteredNews = nodes.filter(({ date }) => date.includes(`${currentYear}`));
      setArticles(filteredNews);
    }
  }, [currentYear]);

  useEffect(() => {
    const nodesYears = nodes.map((item) => new Date(item?.date)?.getFullYear());
    const years = [...new Set(nodesYears)].sort((a, b) => (b - a));
    setAllYears(years);
    setCurrentYear(-1);
  }, [nodes]);

  return (
    <Container withPaddings>
      {header?.length && (
        <Title>
          <Header>{header}</Header>
          <Dropdown
            values={getYearsValues()}
            activeValue={currentYear}
            onSelect={onChangeYear}
          />
        </Title>
      )}

      <NewsLinks
        articles={articles}
        defaultImage={defaultImage}
        maxShown={maxShown}
      />

      {articles.length > MAX_ITEMS && (
        <ShowMore type="button" onClick={maxShown === MAX_ITEMS ? handleOpen : handleClose}>
          <MoreText active={maxShown > MAX_ITEMS}>
            {maxShown > MAX_ITEMS ? 'Show less' : 'Show more'}
          </MoreText>
        </ShowMore>
      )}
    </Container>
  );
};

export default NewsList;
