import React, { FC, useState } from 'react';

import { IMediaCoverage } from 'interfaces/press';

import { MoreText, YoutubeVideo } from 'UI';

import {
  Container, Title, Card, ShowMore, Wrapper,
} from './Videos.styled';

interface IVideos {
  nodes: IMediaCoverage[];
  header?: string;
}

const MAX_ITEMS = 4;

const Videos: FC<IVideos> = ({
  nodes, header,
}) => {
  const [maxShown, setMaxShown] = useState<number>(MAX_ITEMS);

  const handleOpen = () => setMaxShown(nodes.length);

  const handleClose = () => setMaxShown(MAX_ITEMS);

  return (
    <Container withPaddings>
      {header?.length && (
        <Title>{header}</Title>
      )}
      <Wrapper gridOnly>
        {nodes.map(({
          title, id, date, link,
        }, index) => index < maxShown && (
          <Card
            key={id}
            title={title}
            date={date}
            link={link}
          >
            <YoutubeVideo
              key={id}
              url={link}
              title={title}
            />
          </Card>
        ))}
      </Wrapper>

      {nodes.length > MAX_ITEMS && (
        <ShowMore type="button" onClick={maxShown === MAX_ITEMS ? handleOpen : handleClose}>
          <MoreText active={maxShown > MAX_ITEMS}>
            {maxShown > MAX_ITEMS ? 'Show less' : 'Show more'}
          </MoreText>
        </ShowMore>
      )}
    </Container>
  );
};

export default Videos;
