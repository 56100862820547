import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IMediaCoverage } from 'interfaces/press';
import { IQueryPage } from 'interfaces/page';

import NewsList from '../NewsList/NewsList';

const query = graphql`
  query {
    page: strapiMediaCoveragePage {
      meta {
        ...StrapiMetaFragment
      }
    }
    articles: allStrapiMediaCoverage(
      sort: {fields: date, order: DESC}
      filter: {type: {eq: "article"}}
      limit: 40
    ) {
      nodes {
        link
        title
        id: strapiId
        media {
          ...MediaImageFragment
        }
        files {
          localFile {
            ...ImageFragment
          }
          url
          alternativeText
          title
        }
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
`;

interface IMediaCoverageQuery extends IQueryPage {
  articles: {
    nodes: IMediaCoverage[];
  };
}

const MediaCoverage: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IMediaCoverageQuery) => {
      if (!data?.articles?.nodes?.length) return null;

      const { articles, page } = data;

      return (
        <NewsList
          header="Articles"
          nodes={articles.nodes}
          defaultImage={page.meta.preview}
        />
      );
    }}
  />
);

export default MediaCoverage;
