import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

import {
  Layout, SectionTitle, Dropdown as UIDropdown,
} from 'UI';

export const Container = styled(Layout)`
  grid-column: 1/-1;
  align-items: start;
  margin-bottom: 5em;

  grid-template-areas:
    'a a b b b b b b b b'
    'a a c c c c c c c c';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 0;
    margin-bottom: 0;
    grid-template-areas:
      'a a a a a a'
      'b b b b b b'
      'b b b b b b'
      'c c c c c c';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
    row-gap: 20px;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c';
  }
`;

export const Header = styled(SectionTitle)`
  margin-bottom: 0;
`;

export const Title = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: row;
  grid-area: a;
  grid-row: 1;
  margin: 0;
  position: sticky;
  top: 1rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    position: static;
  }
`;

export const DateDropdown = styled.small``;

export const ShowMore = styled.button`
  grid-row: span 1;
  grid-area: c;
  cursor: pointer;
  justify-self: start;
  padding: 0;
`;

export const Dropdown = styled(UIDropdown)`
  font-size: 0.857em;
`;
